<template>
  <v-card
    id="define-map"
    flat
    min-height="550"
    min-width="550"
    max-height="900"
    max-width="1200"
  >
    <v-card
      elevation="8"
      min-height="500"
      min-width="500"
      max-height="800"
      max-width="1200"
    >
      <v-overlay :value="layers.overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div
        id="map"
        class="map"
      />
    </v-card>

    <v-card-actions class="card-action">
      <v-btn
        class="margin-button"
        dark
        color="deep-orange accent-3"
        @click="clearMap"
      >
        Clear
      </v-btn>
      <v-spacer />
      <v-color-picker
        v-model="color"
        dot-size="19"
        mode="hexa"
        hide-inputs
        hide-mode-switch
        width="200"
        canvas-height="100"
        @input="setColorWidth"
      />
      <v-spacer />
      <v-select
        v-model="width"
        class="select"
        :items="widths"
        label="Width"
        outlined
        @change="setColorWidth"
      />
      <v-spacer />
      <v-btn
        class="margin-button"
        color="primary"
        :disabled="checkForm()"
        @click="getAllData"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import 'ol/ol.css';
import { S3_BUCKETS } from '@/constants.js';
import { mapState, mapActions } from 'vuex';
import Overlay from 'ol/Overlay';

export default {
  props: {
    layerForm: {
      type: Object,
      default: null
    },
    layerPolygon: {
      type: File,
      default: null
    }
  },
  data() {
    return {
      raster: undefined,
      source: undefined,
      vector: undefined,
      map: undefined,
      overlay: false,
      global_layer_id: '',
      pathS3: null,
      widths: [1, 2, 3, 4, 5],
      color: '#FF0000',
      width: 1,
      layername: null
    };
  },
  computed: {
    ...mapState(['layers', 'file_transfer', 'customer'])
  },
  async mounted() {
    await this.initiateMap();

    if (this.layers.isEditing) {
      this.color = this.layerForm.color;
      this.width = this.layerForm.width;
    }
  },
  methods: {
    ...mapActions([
      'createGlobalLayerAction',
      'storeGlobalLayers',
      'overlayAction',
      'setUpload',
      'editGlobalLayerAction',
      'getItem',
      'setUpKML',
      'deleteKML',
      'saveDataLayer',
      'startMap',
      'setLayerGeojson',
      'getGEOJSON',
      'getKML',
      'getSHP',
      'storeCustomerLayers',
      'setStylesArray'
    ]),
    async initiateMap() {
      await this.startMap();

      if (!this.layers.isEditing || this.layers.isChangingFile) {
        if (this.layerPolygon.name.includes('.geojson')) {
          await this.setLayerGeojson([this.color, this.width]);
        }

        if (this.layerPolygon.name.includes('.kml') || this.layerPolygon.name.includes('.zip')) {
          const params = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`,
            Body: this.layerPolygon
          };
          this.pathS3 = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          const item = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`
          };
          const data = [params, item, this.layerPolygon.name, this.color, this.width];
          this.layerForm.layer_polygon = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          await this.setUpKML(data);
        }
      } else {
        const layer_obj = {
          Bucket: S3_BUCKETS.global_layer(),
          Key: `${this.layerForm.layer_polygon}`
        };
        const data = [layer_obj, this.layerForm.color, this.layerForm.width];

        if (this.layerForm.layer_type === 'geojson') {
          this.layers.fileChoice = 'geojson';
          await this.getGEOJSON(data);
        }

        if (this.layerForm.layer_type === 'kml') {
          this.layers.fileChoice = 'kml';
          await this.getKML(data);
        }

        if (this.layerForm.layer_type === 'shp') {
          this.layers.fileChoice = 'shp';
          await this.getSHP(data);
        }
      }

      this.setGlobalLayersInteractions();
    },
    setGlobalLayersInteractions() {
      const overlay = new Overlay({
        positioning: 'center-left',
        offset: [10, 0],
        element: document.createElement('div')
      });
      this.layers.map.addOverlay(overlay);

      this.layers.map.on('pointermove', event => {
        let featureDetected = false;
        this.layers.map.forEachFeatureAtPixel(event.pixel, feature => {
          if (feature) {
            featureDetected = true;
            const properties = feature.getProperties();
            let isFirstProperty = true;
            const props = Object.keys(properties).map((item) => {
              if (item !== 'geometry') {
                const result = (isFirstProperty ? '' : '<br />') + `<span><b>${item}:</b> ${properties[item]}</span>`;
                isFirstProperty = false;

                return result;
              }

              return '';
            });
            overlay.setPosition(feature.getGeometry().flatCoordinates);
            overlay.getElement().innerHTML = `
              <style>
                .overlay-style {
                  color: white;
                  font: 12px Open Sans;
                  padding: 5px 10px;
                  background-color: rgba(0, 0, 0, 0.7);
                  border: 1px solid green;
                  border-radius: 5px;
                  z-index: 100;
                }
              </style>
              <div class="overlay-style">${props.join('')}</div>`;
            overlay.getElement().style.display = 'block';
          }
        });

        if (!featureDetected) {
          overlay.getElement().style.display = 'none';
        }
      });
    },
    async clearMap() {
      this.layers.map = null;
      this.layers.renderFileMap = false;
      this.emitEvent();
    },
    emitEvent() {
      this.$emit('closeMap');
    },
    checkForm() {
      return this.layerForm.layer_name === '';
    },
    async getAllData() {
      if (!this.layers.isEditing) {
        if (this.layerPolygon.name.includes('.geojson')) {
          const params = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`,
            Body: this.layerPolygon
          };

          this.layerForm.layer_polygon = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          await this.setUpload(params);
        }

        this.overlay = true;
        this.overlayAction(this.overlay);
        let type = '';

        if (this.layerPolygon.name.includes('.kml')) {
          type = 'kml';
        } else if (this.layerPolygon.name.includes('.geojson')) {
          type = 'geojson';
        } else {
          type = 'shp';
        }

        this.layerForm.color = this.color;
        this.layerForm.width = this.width;
        this.layerForm.layer_type = type;
        this.layerForm.crm_id = this.customer.crm_id || '';

        await this.createGlobalLayerAction(this.layerForm);

        this.overlay = false;
        this.overlayAction(this.overlay);
      } else {
        if (this.layerPolygon != null && this.layerPolygon.name.includes('.geojson')) {
          const params = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`,
            Body: this.layerPolygon
          };

          this.layerForm.layer_polygon = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          await this.setUpload(params);
        }

        this.overlay = true;
        this.overlayAction(this.overlay);
        let type = '';

        if (this.layerPolygon != null && this.layers.isChangingFile) {
          if (this.layerPolygon.name.includes('.kml')) {
            type = 'kml';
          } else if (this.layerPolygon.name.includes('.geojson')) {
            type = 'geojson';
          } else {
            type = 'shp';
          }

          this.layerForm.layer_type = type;
        }

        this.layerForm.color = this.color;
        this.layerForm.width = this.width;

        await this.editGlobalLayerAction(this.layerForm);

        this.overlay = false;
        this.overlayAction(this.overlay);
        this.layers.isChangingFile = false;
      }

      this.storeGlobalLayers();

      if (this.customer.crm_id !== '') {
        this.storeCustomerLayers(this.customer.crm_id);
      }

      this.emitEvent();
    },
    setColorWidth() {
      this.setStylesArray([this.color, this.width]);
      this.layers.map.getLayers().getArray()[1].setStyle(this.layers.styles);
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}
.card-action {
  margin-top: 5px;
}
.margin-button {
  margin-bottom: 30px;
}
.select {
  width:20px;
}
</style>
